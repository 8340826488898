import styles from './Toolbar.module.css';
import { CollectionData } from '../../types/CollectionData.types';
import { DateNavigator } from '../../components/date-navigator/DateNavigator';
import { SearchBar } from '../../components/search-bar/SearchBar';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  collectionData: CollectionData[];
  isFetching: boolean;
  setFilter: (filter: string) => void;
  filter: string;
  weekOffset: number;
  setWeekOffset: Dispatch<SetStateAction<number>>;
  isDraft: boolean;
}

const Toolbar = ({
  collectionData,
  isFetching,
  setFilter,
  filter,
  setWeekOffset,
  weekOffset,
  isDraft,
}: Props) => (
  <div className={styles.container}>
    <DateNavigator
      isFetching={isFetching}
      collectionData={collectionData}
      weekOffset={weekOffset}
      setWeekOffset={setWeekOffset}
      isDraft={isDraft}
    />
    <SearchBar filter={filter} setFilter={setFilter} />
  </div>
);

export { Toolbar };
