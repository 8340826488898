import { BullCardSummaries } from '../bull-card-summaries/BullCardSummaries';
import { BullCard } from '../bull-card/BullCard';
import { BullItem } from '../../types/BullItem.types';
import { DayIndicator } from '../day-indicator/DayIndicator';
import styles from './Day.module.css';
import React from 'react';

interface Props {
  day: string;
  bulls: BullItem[];
  filter: string; // we filter the bull cards by whatever we have been given (bull IDs and comments at this stage)
  filteredBulls: BullItem[];
  isDraft: boolean;
}

/**
 * Calculates total number of serves on bull items
 * @param bulls to be counted bulls
 * @returns total number of serves for displaying
 * When total < 1 return '?'
 * When there are null/empty serves in any bull item return calculated total appended with '+' symbol
 * else return calculated total
 */
const getTotalServesDisplayValue = (bulls: BullItem[]): string => {
  let totalServes = 0;
  let showPlus = false;

  bulls.forEach((bull) => {
    if (bull.serves === null) showPlus = true;
    else totalServes += bull.serves;
  });

  if (totalServes < 1) return '?';

  if (showPlus) return totalServes.toString() + '+';

  return totalServes.toString();
};

/**
 * @param bulls list of bull items
 * @param filter to be applied filter, can be undefined and empty
 * @returns list of bull cards or No collections message if bulls array is empty
 */
export const renderBullCards = (
  bulls: BullItem[],
  filter: string,
  isDraft: boolean,
  filteredBulls: BullItem[] = []
) => {
  // if we don't have any bulls, just render that there are no collections on that day
  if (bulls.length === 0)
    return <div className={styles.noCollection}>No collections</div>;
  if (filter.length === 0) {
    // we are counting the number of unique bulls based on the animal key (bull_id)
    const totalUniqueBulls = new Set(bulls.map((bull) => bull.bull_id)).size;
    return (
      <>
        <BullCardSummaries
          totalBulls={totalUniqueBulls}
          totalServes={getTotalServesDisplayValue(bulls)}
        />
        {bulls.map((bull: BullItem) => (
          <BullCard
            key={bull.id}
            bull={bull}
            textToHighlight=''
            isDraft={isDraft}
          />
        ))}
      </>
    );
  }

  if (filteredBulls.length === 0)
    return <div className={styles.noCollection}>No results</div>;
  return (
    <>
      {filteredBulls.map((bull: BullItem) => (
        <React.Fragment key={bull.id}>
          {/* We want the highlighting to be case insensitive the same as the filter*/}
          {/* We assume all of the comments are uppercase from the backend anyway as the highlighted text*/}
          {/* will appear in uppercase*/}
          <BullCard
            key={bull.id}
            bull={bull}
            textToHighlight={filter.toUpperCase()}
            isDraft={isDraft}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export const Day = ({ day, bulls, filter, filteredBulls, isDraft }: Props) => {
  return (
    <div className={styles.container}>
      <DayIndicator date={day} />
      {renderBullCards(bulls, filter, isDraft, filteredBulls)}
    </div>
  );
};
