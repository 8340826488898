import { ActionTypes } from './redux/actionTypes';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { WeekRoster } from './pages/week-roster/WeekRoster';
import { useCollectionData, useRosterLastUpdatedAt } from './api';

// The roster wrapper is a component rendered only once when the SPA gets loaded.
// It takes the location information from the URI and stores it in the Redux store. That's its ony purpose.
// If we did this in an inner component that itself is dependent on the location, as the WeekRoster is, then we would end up in a circular loop of rendering the WeekRoster component
// when an inner component tries to change the location value (such as when using the location dropdown).
export const RosterWrapper = () => {
  // copy the location URI to our store
  const params = useParams();
  const dispatch = useDispatch();
  dispatch({ type: ActionTypes.UPDATE_LOCATION, payload: params?.location });

  // render the one thing we care about: the week roster
  return (
    <WeekRoster
      useCollectionData={useCollectionData}
      useRosterLastUpdatedAt={useRosterLastUpdatedAt}
    />
  );
};
