import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';
import styles from './DateNavigatorWeekSelector.module.css';
import { Tooltip } from '@mui/material';

interface Props {
  weekOffset: number;
  setWeekOffset: Dispatch<SetStateAction<number>>;
}

export const DateNavigatorWeekSelector = ({
  weekOffset,
  setWeekOffset,
}: Props) => {
  const navigateBackwards = () => {
    setWeekOffset((currentOffset) => currentOffset - 1);
  };

  const navigateForwards = () => {
    setWeekOffset((currentOffset) => currentOffset + 1);
  };

  const isFutureWeek = weekOffset === 1;

  return (
    <div className={styles.container}>
      <Tooltip title='Previous week' arrow>
        <span>
          <button
            className={styles.button}
            onClick={navigateBackwards}
            data-testid='historyWeekButton'
          >
            <FontAwesomeIcon icon={faAngleLeft} className={styles.icon} />
          </button>
        </span>
      </Tooltip>
      <Tooltip title='Next week' arrow>
        <span>
          <button
            className={styles.button}
            disabled={isFutureWeek}
            onClick={navigateForwards}
            data-testid='futureWeekButton'
          >
            <FontAwesomeIcon icon={faAngleRight} className={styles.icon} />
          </button>
        </span>
      </Tooltip>
    </div>
  );
};
