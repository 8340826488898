import styles from './BullCardSummaries.module.css';

interface Props {
  totalBulls: number;
  totalServes: string;
}

const BullCardSummaries = ({ totalBulls, totalServes }: Props) => {
  return (
    <div className={styles.container}>
      <div
        className={[styles.summaryCard, styles.bullTotalContainer].join(' ')}
      >
        <div className={styles.title}>Bulls</div>
        <div className={styles.totals} data-testid='total-bull-cards'>
          {totalBulls}
        </div>
      </div>
      <div className={[styles.summaryCard, styles.servesContainer].join(' ')}>
        <div className={styles.title}>Services</div>
        <div className={styles.totals} data-testid='total-bull-services'>
          {totalServes}
        </div>
      </div>
    </div>
  );
};

export { BullCardSummaries };
