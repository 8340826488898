import { DateTime } from 'luxon';
import {
  getMonthYear,
  getYear,
  getShortMonth,
  getShortMonthYear,
} from '../../helpers/DateHelpers';
import { CollectionData } from '../../types/CollectionData.types';
import styles from './DateNavigator.module.css';
import { Dispatch, SetStateAction } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import { DateNavigatorWeekSelector } from '../date-navigator-week-selector/DateNavigatorWeekSelector';

interface Props {
  collectionData: CollectionData[];
  isFetching: boolean;
  weekOffset: number;
  setWeekOffset: Dispatch<SetStateAction<number>>;
  isDraft: boolean;
}

/**
 * Render the date-year tag based on the collection supplied
 * AB-507: Display months when week-view cover two months
 * @param collectionData
 *  Collection Data that is expected to be sorted by day, with the oldest day first
 *  and the most future day last.
 * @returns
 * - When a collection has a list of the same month, display Month year (e.g. 'May 2022')
 * - When a collection has a list with different months but same year,
 *   display first month - last month year (e.g. 'Jan - Feb 2022')
 * - When a collection has a list with different month and different year,
 *   display first month year - last month year (e.g. ' Dec 2022 - Jan 2023')
 * - When a collection is empty, display the current Month year (e.g. 'April 2022')
 */
const getDisplayMonthYear = (collectionData: CollectionData[]) => {
  if (collectionData.length < 1) return getMonthYear(DateTime.now().toISO());

  // Getting first and last date with the assumption that the collectionData is already sorted by date
  const startDate = collectionData[0].day;
  const lastDate = collectionData.at(-1)?.day;

  const startMonthYear = getMonthYear(startDate);
  if (lastDate === undefined) return startMonthYear;

  const lastMonthYear = getMonthYear(lastDate);
  if (startMonthYear === lastMonthYear) return startMonthYear;

  const startYear = getYear(startDate);
  const endYear = getYear(lastDate);
  if (startYear === endYear)
    return getShortMonth(startDate) + ' - ' + getShortMonthYear(lastDate);

  return (
    getShortMonth(startDate) +
    ' ' +
    startYear +
    ' - ' +
    getShortMonthYear(lastDate)
  );
};

export const DateNavigator = ({
  collectionData,
  isFetching,
  weekOffset,
  setWeekOffset,
  isDraft,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.largeSizeText}>
        {getDisplayMonthYear(collectionData)}
      </div>
      <div className={styles.todayButtonContainer}>
        <Tooltip title='Current week' arrow>
          <span>
            <button
              className={styles.todayButton}
              onClick={() => setWeekOffset(0)}
              data-testid='today-button'
            >
              Today
            </button>
          </span>
        </Tooltip>
      </div>
      <DateNavigatorWeekSelector
        weekOffset={weekOffset}
        setWeekOffset={setWeekOffset}
      />
      {isFetching && <CircularProgress value={100} />}
      {isDraft && (
        <div className={styles.draftIndicator}>
          <div>Draft Roster</div>
        </div>
      )}
    </div>
  );
};
