import styles from './SearchBar.module.css';
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface Props {
  filter: string;
  setFilter: (filter: string) => void;
}

const SearchBar = ({ filter, setFilter }: Props) => {
  const maxLengthSearchBox = 50;
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className={styles.searchIcon}
        />
        <input
          className={styles.searchBox}
          data-testid='searchbox'
          placeholder='Search any keyword to filter your view'
          value={filter}
          onChange={(event) => setFilter(event.target.value)}
          maxLength={maxLengthSearchBox}
        />
        {filter.length > 0 ? (
          <div
            className={styles.clearIconContainer}
            onClick={() => setFilter('')}
            data-testid='clear-filter-button'
          >
            <FontAwesomeIcon icon={faXmark} className={styles.clearIcon} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { SearchBar };
