import styles from './WeekRosterSkeleton.module.css';
import { Day } from './components/day-skeleton/Day';

const daysList = [0, 1, 2, 3, 4, 5, 6].map((i) => <Day key={i} />);

export const WeekRosterSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.daysContainer}>{daysList}</div>
    </div>
  );
};
