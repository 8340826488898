import { ActionTypes } from '../../redux/actionTypes';
import { Dropdown } from '../dropdown/Dropdown';
import { RootState } from '../../redux/reducers';
import { SingleValue } from 'react-select';
import styles from './LocationDropdown.module.css';
import { useDispatch, useSelector } from 'react-redux';

/**
 * List of locations that are currently supported to switch between retreiving bull roster data
 */
const locations: LocationOption[] = [
  { label: 'Newstead', value: 'newstead' },
  { label: 'Vailes', value: 'vailes' },
];

/**
 * Dropdown/select option interface we expect the dropdown to work with
 */
interface LocationOption {
  value: string;
  label: string;
}

/**
 * @param s Label string to be formatted
 * @returns A string correctley formatted with the first character capitalised and the rest of the characters in lower case
 */
const formatLocationLabel = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

/**
 *
 * @returns A wrapper around the Dropdown component that handles location switching between collection sites
 * Newstead and Vailes currently
 */
export const LocationDropdown = () => {
  const dispatch = useDispatch();

  const stateLocation = useSelector<RootState, string>(
    (state) => state.location
  )?.toLowerCase();

  const defaultLocation = {
    label: formatLocationLabel(stateLocation),
    value: stateLocation,
  };

  const handleChange = (option: SingleValue<LocationOption>) => {
    if (option !== null) {
      dispatch({ type: ActionTypes.UPDATE_LOCATION, payload: option.value });
    }
  };
  return (
    <div className={styles.locationDropdown}>
      <Dropdown
        options={locations}
        onChange={handleChange}
        defaultSelectValue={defaultLocation}
      />
    </div>
  );
};
