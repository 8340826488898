import { CollectionData } from '../types/CollectionData.types';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react';
import { Auth } from 'aws-amplify';
import { LastUpdatedAt } from '../types/LastUpdatedAt.types';

/**
 * If the location is NEWSTEAD change it to CENTRE as this is what First Memory (PoC backend) expects.
 * Otherwise, return original location
 * @param location
 * @returns valid location that the backend knows how to handle
 */
const changeNewsteadToCentreLocation = (location: string) => {
  if (location.toLowerCase() === 'newstead') return 'centre';
  return location;
};

/**
 * Function to apply auth with either auth0 or aws cognito depending on what is set in the environment variables
 * @param location farm location e.g. CENTRE | VAILES
 * @param apiResource the sub resource we want e.g. rosters/bull | rosters/last-updated-at
 * @param getAccessTokenSilently auth0 function to retrieve access token this has to be passed in as using the auth0 in this hook will cause infinate rerenders
 * @returns the response of the fetch
 */
const fetchWithAuth = async (
  location: string,
  apiResource: string,
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<string>
) => {
  let eywaEndpoint;
  if (process.env.REACT_APP_AUTH0_ENABLED_FEATURE_FLAG === 'true') {
    eywaEndpoint =
      process.env.REACT_APP_EYWA_ENDPOINT_HTTP_API_GATEWAY ||
      'BUGGER:REACT_APP_EYWA_ENDPOINT_HTTP_API_GATEWAY';
  } else {
    eywaEndpoint =
      process.env.REACT_APP_EYWA_ENDPOINT || 'BUGGER:REACT_APP_EYWA_ENDPOINT';
  }
  const apiUri =
    eywaEndpoint +
    '/locations/' +
    changeNewsteadToCentreLocation(location).toUpperCase() +
    apiResource;

  let token;
  if (process.env.REACT_APP_AUTH0_ENABLED_FEATURE_FLAG === 'true') {
    token = await getAccessTokenSilently();
  } else {
    const userSession = await Auth.currentSession();
    token = userSession.getIdToken().getJwtToken();
  }

  // This is a workaround because 'Authorization' expects a string.
  if (token === undefined) {
    token = ''; // Force fetch to give a failed auth request
  }

  const options: RequestInit = {
    headers: { Authorization: token },
  };

  return await fetch(apiUri, options);
};

/**
 * Fetches roster data for specified location. First Memory (PoC backend) expects the location to be in uppercase.
 * @param location farm location e.g. CENTRE | VAILES
 * @param startDate If no start date is passed in it will default to todays date.
 * @param setIsFetchError optional function that will capture if an error or success occurs on fetch
 * @returns roster data for 7 days starting from start date
 */
export const useCollectionData = (
  location: string,
  startDate: DateTime = DateTime.local(),
  setIsFetchError?: (val: boolean) => void
) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery<CollectionData[], Error>(
    ['collectionData', { location, startDate }],
    async (): Promise<CollectionData[]> => {
      const localDate = startDate.toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");

      const res = await fetchWithAuth(
        location,
        '/rosters/bull?dateFrom=' + localDate + '&periodInDays=' + 7,
        getAccessTokenSilently
      );

      return await res.json();
    },
    {
      onError: () => {
        if (setIsFetchError) {
          setIsFetchError(true);
        }
      },
      onSuccess: () => {
        if (setIsFetchError) {
          setIsFetchError(false);
        }
      },
      // We need to turn react query retries off as we are manually polling data ourselves and
      // the retries are swallowing the errors that we want to action
      retry: false,
    }
  );
};

/**
 *
 * @param location farm location e.g. CENTRE | VAILES
 * @param setIsFetchError optional function that will capture if an error or success occurs on fetch
 * @returns the source timestamp on when the roster data was last updated
 */
export const useRosterLastUpdatedAt = (
  location: string,
  setIsFetchError?: (val: boolean) => void
) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<LastUpdatedAt, Error>(
    ['rosterLastUpdatedAt', { location }],
    async (): Promise<LastUpdatedAt> => {
      const res = await fetchWithAuth(
        location,
        '/rosters/last-updated-at',
        getAccessTokenSilently
      );

      const lastUpdateJsonVal = await res.json();
      const parsedVal: LastUpdatedAt = DateTime.fromISO(
        lastUpdateJsonVal.last_updated_at ?? ''
      );
      return parsedVal;
    },
    {
      onError: () => {
        if (setIsFetchError) {
          setIsFetchError(true);
        }
      },
      onSuccess: () => {
        if (setIsFetchError) {
          setIsFetchError(false);
        }
      },
    }
  );
};
