/**
 * React-select prefers JS styling over CSS Styling
 * TODO: Convert to CSS styles
 */
const PRIMARY_LIGHT_DARKER_COLOUR = '#2C1A48';
export const customStyles = {
  // Selected element
  control: (base) => ({
    ...base,
    border: '1px solid #fff',
    borderRadius: 'clamp(0.05em, 0.35vw, 12em)',
    height: 'clamp(2.25em, 2.75vw, 8em)',
    '&:hover': { border: '1px solid #fff',
                 borderRadius: 'clamp(0.05em, 0.35vw, 12em)',
                 backgroundColor: PRIMARY_LIGHT_DARKER_COLOUR },
    cursor: 'pointer'
  }),
  // Selected text element
  singleValue: (base) => ({
    ...base,
    color: '#fff',
  }),
  // Selected text element text width to prevent ellipsis
  input: (base) => ({
    ...base,
    width: '6em',
  }),
  // Select option list items
  option: (base) => ({
    ...base,
    color: '#fff',
    '&:hover': { backgroundColor: PRIMARY_LIGHT_DARKER_COLOUR },
    cursor: 'pointer',
  }),
  // Select dropdown arrow element
  dropdownIndicator: (base) => ({
    ...base,
    color: '#fff',
    paddingLeft: 'clamp(0.1em, 0.2vw, 10em)',
    paddingRight: 'clamp(0.1em, 0.2vw, 10em)',
    svg: {
      width: 'clamp(1em, 0.5vw, 10em)',
      height: 'clamp(1em, 0.5vw, 10em)',
    },
    '&:hover': { color: '#fff' },
  }),
};
