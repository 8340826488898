import styles from './LoadingIndicator.module.css';
import { CircularProgress } from '@mui/material';

export const LoadingIndicator = () => {
  return (
    <div className={styles.container}>
      <CircularProgress value={100} size={'5vw'} />
      <div className={styles.message}>Loading collection roster ...</div>
    </div>
  );
};
