import Select, {
  components,
  ControlProps,
  SingleValue,
  Theme,
} from 'react-select';
import { customStyles } from './Dropdown.styles.js';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Dropdown.module.css';

interface Props {
  options: SelectOption[];
  onChange: (newValue: SingleValue<SelectOption>) => void;
  defaultSelectValue?: SelectOption | null;
}

interface SelectOption {
  value: string;
  label: string;
}

const PRIMARY_LIGHT_COLOUR = '#43335d';

const Control = ({ children, ...props }: ControlProps<SelectOption, false>) => {
  return (
    <components.Control {...props}>
      <div className={styles.icon}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </div>
      {children}
    </components.Control>
  );
};

export const Dropdown = ({ options, onChange, defaultSelectValue }: Props) => {
  return (
    <div className={styles.select}>
      <Select
        options={options}
        onChange={onChange}
        defaultValue={defaultSelectValue ?? options[0]}
        components={{ Control }}
        isSearchable={false}
        theme={(theme: Theme) => ({
          borderRadius: 5,
          spacing: { ...theme.spacing },
          colors: {
            ...theme.colors,
            primary: PRIMARY_LIGHT_COLOUR,
            primary25: PRIMARY_LIGHT_COLOUR,
            neutral0: PRIMARY_LIGHT_COLOUR,
          },
        })}
        styles={customStyles}
      />
    </div>
  );
};
