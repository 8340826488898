import { BullCard } from '../bull-card-skeleton/BullCard';
import { BullCardSummaries } from '../bull-roster-summaries-skeleton/BullCardSummaries';
import { Skeleton } from '@mui/material';
import styles from './Day.module.css';
export const Day = () => {
  return (
    <div className={styles.container}>
      <div className={styles.dayIndicator}>
        <Skeleton variant='circular' width={'4vw'} height={'4vw'} />
      </div>
      <BullCardSummaries />
      <BullCard />
      <BullCard />
      <BullCard />
    </div>
  );
};
