import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RosterWrapper } from './RosterWrapper';
import { Route, Routes } from 'react-router-dom';
import { Settings } from 'luxon';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { LoadingIndicator } from './components/loading/LoadingIndicator';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

Amplify.configure(awsconfig);

const handleAuthenticateUser = async (
  setIsLoggedIn: (val: boolean) => void,
  isAuthenticated?: boolean,
  loginWithRedirect?: () => void,
  isLoading?: boolean
) => {
  if (process.env.REACT_APP_AUTH0_ENABLED_FEATURE_FLAG === 'true') {
    if (loginWithRedirect !== undefined) {
      // if Auth0 isLoading then we just want to return to prevent unnessary login with redirects while authenticating
      if (isLoading) {
        return;
      }
      if (isAuthenticated) {
        setIsLoggedIn(true);
      } else {
        loginWithRedirect();
      }
    }
  } else {
    let authUser = null;
    try {
      authUser = await Auth.currentAuthenticatedUser();
    } catch (error) {
      // if we an error then there must be no authenticated user so redirect to the cognito hosted sign-in page
      const signedInUser = await Auth.federatedSignIn();
      if (signedInUser) {
        setIsLoggedIn(true);
      }
      return;
    }

    if (authUser) {
      setIsLoggedIn(true);
    }
  }
};

function App() {
  Settings.defaultZone = 'Pacific/Auckland';
  const queryClient = new QueryClient();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  useEffect(() => {
    handleAuthenticateUser(
      setIsLoggedIn,
      isAuthenticated,
      loginWithRedirect,
      isLoading
    );
  }, [isLoading]);

  return isLoggedIn ? (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path='/:location' element={<RosterWrapper />} />
        <Route path='/' element={<RosterWrapper />} />
      </Routes>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  ) : (
    <LoadingIndicator />
  );
}

export default App;
