import { ActionTypes } from '../actionTypes';
import { AnyAction } from '@reduxjs/toolkit';
import {
  DEFAULT_LOCATION,
  VALID_LOCATIONS,
} from '../../helpers/ValidationHelpers';

// ? NOTE: The currently(07/06/2022) preferred state management solution for this project is to use the default react useState hook where we can.
// ? However in some situations where it makes sense e.g. storing the location as this is passed through many components that wouldn't use it
// ? and use redux instead
// TODO: Is redux still needed? A potential refactor to default react state if not necessary.

export interface RootState {
  location: string;
}

const initialState = {
  location: DEFAULT_LOCATION,
};

export const rootReducer = (
  state: RootState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_LOCATION:
      return {
        ...state,
        location: VALID_LOCATIONS.includes(action?.payload?.toLowerCase())
          ? action.payload
          : DEFAULT_LOCATION,
      };
    default:
      return state;
  }
};
