import {
  getDayOfMonth,
  getDayOfWeekShortName,
  isToday,
} from '../../helpers/DateHelpers';
import styles from './DayIndicator.module.css';

interface Props {
  date: string;
}

export const DayIndicator = ({ date }: Props) => {
  return (
    <div className={isToday(date) ? styles.currentDayContainer : styles.container}>
      <div className={styles.content}>
        <div className={styles.weekDayShortName}>{getDayOfWeekShortName(date)}</div>
        <div className={styles.dayOfMonth} data-testid="dayIndicator">{getDayOfMonth(date)}</div>
      </div>
    </div>
  );
};
