import { DateTime } from 'luxon';

export const getDayOfMonth = (date: string): string =>
  DateTime.fromISO(date).toFormat('d');

export const getDayOfWeekShortName = (date: string): string =>
  DateTime.fromISO(date).toFormat('ccc');

export const getMonthYear = (date: string): string =>
  DateTime.fromISO(date).toFormat('LLLL y');

export const getShortMonth = (date: string): string =>
  DateTime.fromISO(date).toFormat('LLL');

export const getShortMonthYear = (date: string): string =>
  DateTime.fromISO(date).toFormat('LLL y');

export const getYear = (date: string): string =>
  DateTime.fromISO(date).toFormat('y');

export const isToday = (date: string): boolean =>
  DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT) ===
  DateTime.local().toLocaleString(DateTime.DATE_SHORT);

export const getWeekRange = (startDate: DateTime, endDate: DateTime): string =>
  startDate.toFormat('ccc dd/LL/yy') + ' - ' + endDate.toFormat('ccc dd/LL/yy');

export const getMondayOfCurrentWeek = (): DateTime =>
  DateTime.local().startOf('week');

export const getMondayOfNextWeek = (): DateTime =>
  DateTime.local().plus({ days: 7 }).startOf('week');
