import { LocationDropdown } from '../location-dropdown/LocationDropdown';
import { Logo } from '../logo/Logo';
import { DataUpdateIndicator } from '../data-update-indicator/DataUpdateIndicator';
import styles from './NavBar.module.css';
import { DateTime } from 'luxon';

interface Props {
  lastUpdatedAt: DateTime | undefined;
  refetch: () => void;
}

export const NavBar = ({ lastUpdatedAt, refetch }: Props) => {
  return (
    <div className={styles.container}>
      <Logo />
      <div className={styles.rightGroup}>
        <DataUpdateIndicator lastUpdatedAt={lastUpdatedAt} refetch={refetch} />
        <LocationDropdown />
      </div>
    </div>
  );
};
