import { Skeleton } from '@mui/material';
import styles from './BullCardSummaries.module.css';

export const BullCardSummaries = () => {
  return (
    <div className={styles.container}>
      <Skeleton
        variant='rectangular'
        width={'2vw'}
        height={'3.7vw'}
        className={styles.summaryCard}
      />
      <Skeleton
        variant='rectangular'
        width={'2vw'}
        height={'3.7vw'}
        className={styles.summaryCard}
      />
    </div>
  );
};
