import { CollectionData } from '../../types/CollectionData.types';
import { Day } from '../../components/day/Day';
import { LoadingIndicator } from '../../components/loading/LoadingIndicator';
import { NavBar } from '../../components/nav-bar/NavBar';
import { RootState } from '../../redux/reducers';
import styles from './WeekRoster.module.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Toolbar } from '../../components/tool-bar/Toolbar';
import { UseQueryResult } from 'react-query';
import { BullItem } from '../../types/BullItem.types';
import { DateTime } from 'luxon';
import {
  getWeekRange,
  getMondayOfCurrentWeek,
} from '../../helpers/DateHelpers';
import { TimeInMilliseconds } from '../../helpers/TimeHelpers';
import { useInterval } from '../../hooks';
import { WeekRosterSkeleton } from '../week-roster-skeleton/WeekRosterSkeleton';
import { FailedFetchSnackbar } from '../../components/failed-fetch-snackbar/FailedFetchSnackbar';
import { LastUpdatedAt } from '../../types/LastUpdatedAt.types';

interface Props {
  useCollectionData: (
    location: string,
    startDate: DateTime,
    setIsFetchError: (val: boolean) => void
  ) => UseQueryResult<CollectionData[], Error>;
  useRosterLastUpdatedAt: (
    location: string,
    setIsFetchError: (val: boolean) => void
  ) => UseQueryResult<LastUpdatedAt, Error>;
}

class DecoratedDay {
  day: string;
  bullItems: BullItem[];
  filteredBulls: BullItem[];
  isDraft: boolean;
  constructor(
    day: string,
    bullItems: BullItem[],
    filteredBulls: BullItem[],
    isDraft: boolean
  ) {
    this.day = day;
    this.bullItems = bullItems;
    this.filteredBulls = filteredBulls;
    this.isDraft = isDraft;
  }
}

/**
 * Applies a filter on a list of bulls and returns only the bulls that go through the filter.
 * @param bulls To be filtered bulls. List can be empty.
 * @param filter To be applied filter. Filter can be empty which means no filtering will be applied.
 * @returns Filtered bulls.
 */
export const filterBulls = (bulls: BullItem[], filter: string): BullItem[] => {
  // we don't do anything to the bulls of there are none or nothing needs to be filtered
  if (bulls.length === 0 || filter.length === 0) return bulls;

  // we do have bulls and we have to apply the filter
  const upperCaseFilter = filter.toUpperCase();
  return bulls.filter(
    (bull) =>
      bull.bull_id.includes(filter) ||
      bull.comment.toUpperCase().includes(upperCaseFilter)
  );
};

export const getEmptyResultsMessage = (
  isCollectionEmpty: boolean,
  startDate: DateTime,
  endDate: DateTime
): string => {
  return (
    'No ' +
    (isCollectionEmpty ? 'collections' : 'results') +
    ' for ' +
    getWeekRange(startDate, endDate)
  );
};

const getDateFromOffset = (
  startDate: DateTime,
  weekOffset: number
): DateTime => {
  if (weekOffset === 0) {
    return startDate;
  }
  return startDate.plus({ weeks: weekOffset });
};

export const WeekRoster = ({
  useCollectionData,
  useRosterLastUpdatedAt,
}: Props) => {
  const [filter, setFilter] = useState<string>('');
  const stateLocation = useSelector<RootState, string>(
    (state) => state.location
  );
  const [dateTimeDependency, setDateTimeDependency] = useState(new Date());
  const [weekOffset, setWeekOffset] = useState<number>(0);
  const [isFetchError, setIsFetchError] = useState(false);
  const weekEndDate = getDateFromOffset(
    getMondayOfCurrentWeek().plus({ days: 6 }),
    weekOffset
  );
  const {
    data: collectionData,
    error,
    isLoading,
    refetch: collectionDataRefetch,
    isFetching,
  } = useCollectionData(
    stateLocation,
    getDateFromOffset(getMondayOfCurrentWeek(), weekOffset),
    setIsFetchError
  );
  const { data: rosterLastUpdatedAt, refetch: rosterLastUpdateRefetch } =
    useRosterLastUpdatedAt(stateLocation, setIsFetchError);

  /**
   * Group last update refetch and collectionData refetch into one function
   * as we want to keep them in sync
   * */
  const refetch = () => {
    collectionDataRefetch();
    rosterLastUpdateRefetch();
  };

  useInterval(() => {
    setDateTimeDependency(new Date());
  }, 5 * TimeInMilliseconds.Minute); // polls for updates every 5 minute

  useEffect(() => {
    refetch();
  }, [stateLocation, weekOffset, dateTimeDependency]);

  if (isLoading)
    return (
      <>
        <NavBar refetch={refetch} lastUpdatedAt={rosterLastUpdatedAt} />
        <LoadingIndicator />
      </>
    );

  if (error instanceof Error && collectionData === undefined)
    return <div>{error.message}</div>;

  if (collectionData === undefined) return <div>No data available</div>;

  const decoratedCollection: DecoratedDay[] = [];

  // If there are no results that match the filter then we want to set isAnyResultsToShow to false and just return the 'No results for current week' result
  let isAnyResultsToShow = false;
  let isNoBullItemsFound = true;
  let isDraftDayFound = false;

  const upperCaseFilter = filter.toUpperCase();
  collectionData.forEach((day) => {
    if (!isDraftDayFound) {
      isDraftDayFound = day.is_draft;
    }
    const filteredBullItems =
      filter.length > 0 ? filterBulls(day.items, upperCaseFilter) : day.items;
    const dayData = new DecoratedDay(
      day.day,
      day.items,
      filteredBullItems,
      day.is_draft
    );
    decoratedCollection.push(dayData);

    if (isNoBullItemsFound) {
      isNoBullItemsFound = day.items.length === 0;
    }

    if (!isAnyResultsToShow) {
      isAnyResultsToShow = dayData.filteredBulls.length > 0;
    }
  });

  return (
    <>
      <NavBar refetch={refetch} lastUpdatedAt={rosterLastUpdatedAt} />
      <Toolbar
        collectionData={collectionData}
        isFetching={isFetching}
        setFilter={setFilter}
        filter={filter}
        weekOffset={weekOffset}
        setWeekOffset={setWeekOffset}
        isDraft={isDraftDayFound}
      />
      {!isFetching ? (
        <div className={styles.container}>
          <div className={styles.daysContainer}>
            {isAnyResultsToShow ? (
              decoratedCollection.map((collection: DecoratedDay) => (
                <Day
                  key={collection.day}
                  day={collection.day}
                  bulls={collection.bullItems}
                  filter={filter}
                  filteredBulls={collection.filteredBulls}
                  isDraft={isDraftDayFound}
                />
              ))
            ) : (
              <div className={styles.noFilterResultsContainer}>
                <div
                  className={styles.noFilterResultsMessage}
                  data-testid='noCollectionsMessage'
                >
                  {getEmptyResultsMessage(
                    isNoBullItemsFound,
                    getDateFromOffset(getMondayOfCurrentWeek(), weekOffset),
                    weekEndDate
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <WeekRosterSkeleton />
      )}
      <FailedFetchSnackbar
        isFetchError={isFetchError}
        refetch={refetch}
        setIsFetchError={setIsFetchError}
      />
    </>
  );
};
