import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import './SnackBarStyles.css';
import styles from './FailedFetchSnackbar.module.css';

interface Props {
  isFetchError: boolean;
  setIsFetchError: (val: boolean) => void;
  refetch: () => void;
}

const snackBarPosition: SnackbarOrigin = {
  horizontal: 'right',
  vertical: 'bottom',
};

export const FailedFetchSnackbar = ({
  isFetchError,
  setIsFetchError,
  refetch,
}: Props) => {
  return (
    <Snackbar
      anchorOrigin={snackBarPosition}
      open={isFetchError}
      className={styles.snackbar}
      onClose={() => {
        setIsFetchError(false);
      }}
      message={
        <div>
          <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
          <span>
            Unable to retrieve latest data. The next refresh will happen in 5
            minutes.
          </span>
          <span className={styles.buttonDecoration}>
            <Button variant='text' onClick={() => refetch()}>
              Retry now
            </Button>
          </span>
        </div>
      }
    />
  );
};
