import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './DataUpdateIndicator.module.css';
import { DateTime, Duration, Interval } from 'luxon';
import { useInterval } from '../../hooks';
import { TimeInMilliseconds } from '../../helpers/TimeHelpers';
import { useState } from 'react';

interface Props {
  lastUpdatedAt: DateTime | undefined;
  refetch: () => void;
}

/**
 *
 * @param lastUpdatedAt DateTime object indicating the last time the data was updated
 * @param currentTime DateTime object indicating the current local system time
 * @returns A last updated at message based on the interval between the lastUpdatedAt and currentTime
 */
const getLastUpdateTimeMessage = (
  lastUpdatedAt: DateTime | undefined,
  currentTime: DateTime
) => {
  if (lastUpdatedAt === undefined || !lastUpdatedAt.isValid) {
    return ' at an unknown time';
  }
  const duration: Duration = Interval.fromDateTimes(
    lastUpdatedAt,
    currentTime
  ).toDuration(['weeks', 'days', 'hours', 'minutes', 'seconds']);

  const weeksRounded = Math.floor(duration.weeks);
  const daysRounded = Math.floor(duration.days);
  const hoursRounded = Math.floor(duration.hours);
  const minutesRounded = Math.floor(duration.minutes);
  const secondsRounded = Math.floor(duration.seconds);
  if (weeksRounded > 0) {
    if (duration.weeks > 52) {
      return ' a long time ago';
    }
    return ` ${weeksRounded} week${weeksRounded === 1 ? '' : 's'} ago`;
  } else if (daysRounded > 0) {
    return ` ${daysRounded} day${daysRounded === 1 ? '' : 's'} ago`;
  } else if (hoursRounded > 0) {
    return ` ${hoursRounded} hour${hoursRounded === 1 ? '' : 's'} ago`;
  } else if (minutesRounded > 0) {
    return ` ${minutesRounded} minute${minutesRounded === 1 ? '' : 's'} ago`;
  } else if (secondsRounded >= 30 && secondsRounded < 60) {
    return ' 30 seconds ago';
  }
  return ' just now';
};

export const DataUpdateIndicator = ({ refetch, lastUpdatedAt }: Props) => {
  const [dateTimeDependency, setDateTimeDependency] = useState(
    DateTime.local()
  );

  useInterval(() => {
    setDateTimeDependency(DateTime.local());
  }, 10 * TimeInMilliseconds.Second); // updates the current time every 10 seconds

  return (
    <div className={styles.container}>
      <FontAwesomeIcon
        icon={faSync}
        className={styles.icon}
        onClick={() => refetch()}
      />
      <div className={styles.updateMessage}>
        Roster last updated
        {getLastUpdateTimeMessage(lastUpdatedAt, dateTimeDependency)}
      </div>
    </div>
  );
};
