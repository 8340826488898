import { Skeleton } from '@mui/material';
import styles from './BullCard.module.css';

export const BullCard = () => {
  return (
    <div className={styles.container}>
      <Skeleton
        variant='rectangular'
        width={'100%'}
        height={'3.7vw'}
        className={styles.card}
      />
    </div>
  );
};
