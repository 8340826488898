let config = {
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    mandatorySignIn: true,
    oauth: {
      domain: process.env.REACT_APP_AUTH_DOMAIN,
      scope: ['openid'],
      redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_SIGNOUT,
      responseType: 'code',
    },
  },
};
export default config;
