export const DEFAULT_LOCATION = 'newstead';
export const VALID_LOCATIONS = [DEFAULT_LOCATION, 'vailes'];

type Serves = string | number | null;

export const validateServes = (serves: Serves) => {
  if (serves === null)
    return '?';

  if (typeof serves === 'string') {
    const parsed = parseInt(serves);
    return isNaN(parsed)
      ? '?'
      : handleNumberServes(parsed);
  }

  return handleNumberServes(serves);
};

const handleNumberServes = (num: number): string | number => num > 0 && num < 10
    ? num
    : '?';
