import { BullItem } from '../../types/BullItem.types';
import styles from './BullCard.module.css';
import { validateServes } from '../../helpers/ValidationHelpers';
import React from 'react';

interface Props {
  bull: BullItem;
  textToHighlight: string; // we highlight text in the bull card (bull ID and comment); this is the to be highlighted text
  isDraft: boolean;
}

/** formats te given text and highlights the containing filters if any
 * @param text the to be formatted text
 * @param textToHighlight the subtext that shall be highlights
 */
const highlightTextFragments = (text: string, textToHighlight: string) => {
  // quick exit if there's nothing to highlight
  if (textToHighlight.length === 0) return text;

  // split the to be formatted text by the text highlights, so we have the correct text fragments handy right away
  // if there is only one fragment coming back, nothing needs to be fighlighted
  const textFragments = text.split(textToHighlight);
  if (textFragments.length === 1) return text;

  // alright, we've got at least two text fragments; now let's put the to be highlighted text inbetween all of them
  const firstTextFragment = textFragments[0];
  textFragments.splice(0, 1); // remove the first element from the array
  let i = 0; // all of our fragments need to be uniquely identified, so React can access them in the DOM properly
  return (
    <>
      {firstTextFragment}
      {textFragments.map((fragment) => (
        <React.Fragment key={++i}>
          <span className={styles.highlight} data-testid='highlightedText'>
            {textToHighlight}
          </span>
          {fragment}
        </React.Fragment>
      ))}
    </>
  );
};

export const BullCard = ({ bull, textToHighlight, isDraft }: Props) => {
  // format the bull ID if necessary
  const formattedBullId = highlightTextFragments(bull.bull_id, textToHighlight);

  // do the same formatting with the comment if necessary
  const comment = bull.comment;
  // formattedComment will be added back in when we get to this story https://licnz.atlassian.net/browse/AB-511
  const formattedComment = highlightTextFragments(comment, textToHighlight);

  return (
    <div
      className={[
        styles.card,
        isDraft ? styles.cardDraftTheme : styles.cardFinalTheme,
      ].join(' ')}
      data-testid='bull-card'
    >
      <div className={styles.bullInfo}>
        <div className={styles.bullId}>{formattedBullId}</div>
        <div
          className={
            comment.length < 10
              ? styles.bullComment
              : styles.bullCommentMultipleLine
          }
        >
          {formattedComment}
        </div>
      </div>
      <div className={styles.servesContainer}>
        <div className={styles.serves}>{validateServes(bull.serves)}</div>
      </div>
    </div>
  );
};
